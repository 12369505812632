<template>
  <AuthWrapper>
    <!--    <p class="auth-notice">{{ $t('auth.dont_have_account') }} <router-link to="/auth/register">{{  $t('auth.sign_up') }}</router-link></p>-->
    <div class="auth-contents">
      <a-form
        @finish="login"
        :model="loginState"
        layout="vertical"
        class="sign-form"
        :rules="rules"
      >
        <div class="header-block">
          <sdHeading as="h3" class="text-center">
            {{ $t("auth.sign_in_to") }} {{ $t("brokvisor") }}
            <p class="small-text">{{ $t("auth.enter_your_data") }}</p>
          </sdHeading>
        </div>

        <div class="input-block">
          <a-form-item name="email" :label="$t('auth.email')">
            <a-input type="email" v-model:value="loginState.email" />
          </a-form-item>
          <a-form-item name="password" :label="$t('auth.password')">
            <a-input-password type="password" v-model:value="loginState.password" />
          </a-form-item>
        </div>
        <div class="auth-form-action">
          <a-checkbox @change="onChange">{{
            $t("auth.keep_me_logged")
          }}</a-checkbox>
          <router-link class="forgot-pass-link" to="/auth/forgotPassword"
            >{{ $t("auth.forgot_password") }}
          </router-link>
        </div>
        <div class="button-block">
          <a-form-item>
            <sdButton
              class="btn-signin element-center"
              htmlType="submit"
              type="primary"
              size="large"
              :loading="isLoading"
            >
              {{ isLoading ? $t("auth.loading") : $t("auth.sign_in") }}
            </sdButton>
          </a-form-item>
        </div>
      </a-form>
      <a-modal
        v-model:visible="showErrorModal"
        title="Login Error"
        @ok="closeModal"
        @cancel="closeModal"
      >
        {{ $t("auth.login_error_text") }}
      </a-modal>
    </div>
  </AuthWrapper>
</template>

<script setup>
import { computed, reactive, ref, onUpdated } from "vue";
import { AuthWrapper } from "./style";
import { useAuthStore } from "@/stores/AuthStore";
import { useI18n } from "vue-i18n";

const { t, locale  } = useI18n();

const authStore = useAuthStore();
const showErrorModal = ref(false);
const isLoading = computed(() => authStore.loading);

const showModal = () => {
  authStore.loading = false;
  showErrorModal.value = true;
};

const closeModal = () => {
  showErrorModal.value = false;
};

const login = () => {
  authStore.loginUser(loginState)
  .then(() => {
      locale.value = 'es';
    }).catch(() => {
    showModal();
  });
};

const onChange = (checked) => {
  checked.value = checked;
};

onUpdated(() => {
  console.info("Update");
  console.info(authStore.login);
});

const loginState = reactive({
  email: "",
  password: "",
});

const rules = {
  email: [
    { required: true, message: t("auth.rules.email"), trigger: "change" },
  ],
  password: [
    { required: true, message: t("auth.rules.password"), trigger: "change" },
  ],
};
</script>
<style scoped lang="scss">
.auth-contents {
  height: 100%;
}
.sign-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

// FORM HEADER
.lavNKW .auth-contents form h1 {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ant-input-password.ant-input-affix-wrapper {
  padding: 0 4px 0 0;
}

@media only screen and (max-width: 991px) {
  .duPWIV {
    padding: 85px !important;
  }
  .auth-contents {
    height: 72vh;
  }
}
</style>
